<template>
  <section id="secStoreAlbumsPhysical">
    <h2 id="hStoreAlbumsPhysical">{{ $t("headers.physical-albums") }}</h2>
    <div id="albumInfo" class="readFriendlyLayout">
      <p>
        {{ $t("view.store.physical-albums-text") }}
      </p>
      <p>
        {{ $t("view.store.physical-albums-price") }}
      </p>
    </div>

    <div id="physicalAlbums" class="physicalAlbumsContainer flexContainerRow">
      <article
        class="albumStoreContainer"
        v-for="album in physicalAlbums"
        :key="album.title"
      >
        <h4>{{ album.title }}</h4>
        <div class="album">
          <img
            :src="getImagePath(album.title)"
            :alt="`To Dust - ${album.title}`"
          />
          <audio controls="controls" preload="none" class="albumPreview">
            <source :src="getAudioPath(album.previewTrack)" type="audio/mpeg" />
            {{ $t("error-message.no-audio-support") }}
          </audio>
        </div>
        <div>{{ $t("view.store.physical-albums-in-stock") }} 50+</div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PhysicalAlbums',
  data() {
    return {
      physicalAlbums: [
        {
          title: 'False god of death',
          previewTrack: 'Shades',
          audioPath: '../../assets/audio/ToDust-shades.mp3',
          imagePath: '../../assets/images/album-covers/false-god-of-death.jpg',
        },
        {
          title: 'Against the real',
          previewTrack: 'Midas Syndrome',
          audioPath: '../../assets/audio/ToDust-midas-syndrome.mp3',
          imagePath: '../../assets/images/album-covers/against-the-real.jpg',
        },
        {
          title: 'State of nothing',
          previewTrack: 'Nihil Est',
          audioPath: '../../assets/audio/ToDust-nihil-est.mp3',
          imagePath: '../../assets/images/album-covers/state-of-nothing.jpg',
        },
      ],
    };
  },
  methods: {
    getImagePath(albumTitle) {
      return require(`../../assets/images/album-covers/${albumTitle
        .toLowerCase()
        .replaceAll(' ', '-')}.jpg`);
    },
    getAudioPath(songTitle) {
      return require(`../../assets/audio/ToDust-${songTitle
        .toLowerCase()
        .replaceAll(' ', '-')}.mp3`);
    },
  },
};
</script>

<style scoped lang="scss">
.physicalAlbumsContainer {
  justify-content: center;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.albumStoreContainer {
  text-align: center;
  border: 1px solid #646154;
  max-width: 340px;
  border-radius: 5px;
  margin: 10px;
}

.albumStoreContainer img {
  width: 90%;
}

.albumPreview {
  bottom: 0;
  width: 100%;
}
</style>
