<template>
  <section id="secStoreAlbumsDigital">
    <h2 id="hStorePhysicalAlbumsDigital">
      {{ $t("headers.digital-distribution") }}
    </h2>
    <div id="digitalDistributionInfo" class="readFriendlyLayout">
      <p>
        {{ $t("view.store.digital-distribution") }}
      </p>

      <IconLinks :iconLinks="distributionVendors" />
    </div>
  </section>
</template>

<script>
import IconLinks from '../Common/IconLinks.vue';

export default {
  name: 'DigitalDistribution',
  components: {
    IconLinks,
  },
  data() {
    return {
      distributionVendors: [
        {
          title: 'Amazon',
          url:
            'https://www.amazon.com/False-God-Death-Dust/dp/B07PPSX313/ref=cm_cr_arp_d_product_top?ie=UTF8',
          imageLocation: 'images/icons/digital-distribution-icons/shop',
          imageType: 'png',
        },
        {
          title: 'Cdbaby',
          url: 'https://store.cdbaby.com/cd/todust4',
          imageLocation: 'images/icons/digital-distribution-icons/shop',
          imageType: 'png',
        },
        {
          title: 'Google Play',
          url:
            'https://play.google.com/store/music/artist?id=Ajlef5s3bo5sqvjycrynzwxm7ta',
          imageLocation: 'images/icons/digital-distribution-icons/shop',
          imageType: 'png',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
