<template>
  <div>
    <DigitalDistribution />
    <PhysicalAlbums />
    <Merchandise />
  </div>
</template>

<script>
import DigitalDistribution from '../components/Store/DigitalDistribution.vue';
import PhysicalAlbums from '../components/Store/PhysicalAlbums.vue';
import Merchandise from '../components/Store/Merchandise.vue';

export default {
  name: 'Store',
  components: {
    DigitalDistribution,
    PhysicalAlbums,
    Merchandise,
  },
};
</script>
