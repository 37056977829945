<template>
  <section id="secStoreMerch">
    <h2 id="hStoreMerch">{{ $t("headers.merchandise") }}</h2>
    <p class="centerContent merchandise">
      {{ $t("view.store.merchandise-text") }}
      <a href="https://to-dust.myspreadshop.se/" target="_blank"
        >https://to-dust.myspreadshop.se/</a
      >.
    </p>
    <div id="storeSpreadshirt" class="storeSpreadshirt">
      <a href="https://to-dust.myspreadshop.se/" target="_blank">
        <img
          src="../../assets/images/t-shirt.jpg"
          alt="To Dust T-hirt"
          class="storeImage"
        />
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Merchandise',
};
</script>

<style lang="scss" scoped>
.centerContent {
  text-align: center;
}

.storeSpreadshirt {
  width: 100%;
  text-align: center;
}

.storeImage {
  max-width: 90%;
  border-radius: 20px;
}

.merchandise {
  a {
    color: #c4c1b4;
    font-weight: 1000;
  }
}
</style>
